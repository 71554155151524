export default class StringValidators {
  static checkvalidlength = (unique) => {
    if (unique.length > 0) return true;
    else return false;
  };
  static emailvalidation = (text) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (text.match(mailformat)) return true;
    else return false;
  };
}

export function hasDuplicatesIgnoreCase(strings) {
  // Convert all strings to lowercase
  const lowercaseStrings = strings.map((str) => str.toLowerCase());

  // Use a Set to track unique values
  const uniqueStrings = new Set();

  for (const str of lowercaseStrings) {
    if (uniqueStrings.has(str)) {
      return true; // Duplicate found
    }
    uniqueStrings.add(str);
  }

  return false; // No duplicates
}
