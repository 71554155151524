import { useState } from "react";
import CustomButton from "../CustomButton";
import ToggleSwitch from "../ToggleSwitch";
import "./index.css";
import StringValidators, {
  hasDuplicatesIgnoreCase,
} from "../../util/StringValidators";
import ConferenceServices from "../../services/ConferenceServices";
import CustomSnackbar from "../Snackbar";
import Loadingspinner from "../LoadingProgressIndicator";
import InvitePicker from "../InvitePicker";
import TimeManipulators from "../../util/TimeManipulators";
import { useRef } from "react";
import { StringManipulators } from "../../util/StringManipulators";
import { useSelector } from "react-redux";

function CreateConferenceModal(props) {
  const config = useSelector((state) => state.hootConfig);
  const [firstName, setfirstName] = useState(props.username);
  const [lastName, setlastName] = useState("");
  const [listenOnly, setListenOnly] = useState(false);
  const [webCamOn, setwebCamOn] = useState(false);
  const [participantList, setparticipantList] = useState([]);
  const [snackbar, setsnackbar] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState(null);
  const [snackbarMessageType, setsnackbarMessageType] = useState(null);
  const [isloading, setisloading] = useState(false);
  const [showInviteMenu, setshowInviteMenu] = useState(false);
  const [inviteValue, setinviteValue] = useState("");
  const [pdfValue, setpdfValue] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const fileInputRef = useRef(null);
  let epochStart = -1;
  let epochEnd = -1;
  const [invitationValue, setInvitationValue] = useState({});
  const handleButtonClick = () => {
    fileInputRef.current.click(); // Click the hidden file input element
  };
  const addPDFValue = async (pdfFile) => {
    try {
      const base64Data = await StringManipulators.convertPdfToBase64(pdfFile);
      // console.log('Base64 representation of PDF:', base64Data);
      const document = {
        name: pdfFile.name,
        base64_representation: base64Data,
      };
      setpdfValue(document);
    } catch (error) {
      console.error("Failed to convert PDF to base64:", error);
      showSnackbar("error", "Failed to convert PDF to base64:" + error);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      // Perform file upload logic here, e.g. call a function or update state with the selected file
      // console.log('Selected file:', file);
      if (file.size <= 300 * 1024) {
        // Maximum size of 300 KB (300 * 1024 bytes)
        // Perform file upload logic here, e.g. call a function or update state with the selected file
        // console.log('Selected file:', file);
        addPDFValue(file);
      } else {
        console.error("File size exceeds the maximum limit of 300 KB.");
        // Handle error, e.g. display an error message
        showSnackbar(
          "error",
          "The PDF file you are trying to upload exceeds the file size limit set"
        );
      }
    } else {
      console.error("Invalid file format. Please select a .pdf or .docx file.");
      // Handle error, e.g. display an error message
    }
  };

  const removePDFValue = (event) => {
    event.preventDefault();
    setpdfValue(null);
  };

  const changeInviteValue = (value) => {
    setinviteValue(value);
  };

  const changeShowInvite = (event) => {
    event.preventDefault();
    setshowInviteMenu(!showInviteMenu);
  };

  const changeFirstNameValue = (event) => {
    event.preventDefault();
    setfirstName(event.target.value);
  };
  const changeLastNameValue = (event) => {
    event.preventDefault();
    setlastName(event.target.value);
  };
  const toggleListenOnly = () => {
    setListenOnly(!listenOnly);
  };
  const toggleWebcamOn = () => {
    setwebCamOn(!webCamOn);
  };
  const changeParticipants = (event) => {
    event.preventDefault();
    setparticipantList(event.target.value);
  };

  const createhandler = async (e) => {
    e.preventDefault();
    // console.log('Pressed create button')
    if (!StringValidators.checkvalidlength(firstName)) {
      showSnackbar("error", "First name is empty");
      console.log("First name is empty");
      return;
    }
    if (!StringValidators.checkvalidlength(lastName)) {
      showSnackbar("error", "Conference unique ID is empty");
      console.log("Unique ID is empty");
      return;
    }
    const confname = firstName + "-" + lastName;
    //console.log(confname);
    const participantarr =
      participantList.length > 0
        ? participantList
            .replace(/\n/g, ",")
            .split(",")
            .map((element) => {
              return element.trim();
            })
        : [];
    const features =
      pdfValue === null
        ? {
            join_audio_on: listenOnly,
            join_webcam_on: webCamOn,
            branding_skin_color: "#97340F",
            branding_logo_url:
              "https://storage.googleapis.com/hoot-mx-api/hoot_2000_1012.png",
            invitation: invitationValue,
          }
        : {
            join_audio_on: listenOnly,
            join_webcam_on: webCamOn,
            branding_skin_color: "#97340F",
            branding_logo_url:
              "https://storage.googleapis.com/hoot-mx-api/hoot_2000_1012.png",
            documents: {
              pdf_list: [pdfValue],
            },
            invitation: invitationValue,
          };
    console.log(
      "Invitation value while creating conference is",
      invitationValue
    );
    console.log("Epoch value is", epochStart);
    console.log("Features is: ", features);
    const owners = [props.emailid];
    let flag = true;
    for (let i = 0; i < participantarr.length; i++) {
      if (
        participantarr[i] !== "" &&
        !StringValidators.emailvalidation(participantarr[i])
      ) {
        flag = false;
        break;
      }
    }
    console.log("Participant array is", participantarr);
    if (flag === false) return;
    const hasDuplicates = hasDuplicatesIgnoreCase(participantarr);
    if (hasDuplicates) {
      showSnackbar(
        "error",
        "Duplicate email ids found ! Remove duplicates to create conference."
      );
      return;
    }
    setisloading(true);
    const res = await ConferenceServices.createConference(
      confname,
      props.getIdTokenClaims,
      props.hootconfig,
      participantarr,
      owners,
      features
    );
    setisloading(false);
    if (res.result === false) showSnackbar("error", res.message);
    else {
      showSnackbar("success", "Conference successfully created");
      setlastName("");
      setfirstName(props.username);
      setparticipantList([]);
      setinviteValue("");
      setSelectedTimeZone(null);
      setInvitationValue({});
      setTimeout(() => {
        closeModalDisplay();
      }, 1500);
    }
    console.log("Got response: ", res);
  };
  const showSnackbar = (messageType, message) => {
    // console.log('setting snackbar value')
    setsnackbarMessage(message);
    setsnackbarMessageType(messageType);
    setsnackbar(true);
    setTimeout(() => {
      setsnackbar(false);
      setsnackbarMessageType(null);
      setsnackbarMessage(null);
    }, 1500);
  };
  const closeModalDisplay = () => {
    if (isloading) return;
    props.closeModal();
  };

  const setInvite = (event, selectedDate, fromTime, toTime) => {
    event.preventDefault();
    if (selectedDate.length === 0) {
      showSnackbar("error", "Please select a date");
      return;
    }
    console.log("Selected date is: ", selectedDate);
    if (selectedTimeZone === null) {
      showSnackbar("error", "Please select a timezone");
      return;
    }
    const formattedFromTime =
      fromTime.hour().toString() + ":" + fromTime.minute().toString();
    const formattedToTime =
      toTime.hour().toString() + ":" + toTime.minute().toString();
    let fromCombinedTime;
    let toCombinedTime;
    if (!Array.isArray(selectedDate)) {
      // console.log('Selected date: ',selectedDate)
      if (
        TimeManipulators.isSecondTimeGreater(
          formattedFromTime,
          formattedToTime
        ) === false
      ) {
        showSnackbar(
          "error",
          "Meeting start time should be less than meeting end time"
        );
        return;
      }
      fromCombinedTime = TimeManipulators.combineDateTime(
        selectedDate,
        fromTime.hour().toString(),
        fromTime.minute().toString()
      );
      console.log("From Time in local time zone is: ", fromCombinedTime);
      fromCombinedTime = TimeManipulators.changeTimezone(
        fromCombinedTime,
        selectedTimeZone.value
      );
      console.log(
        "Resultant time from time zone conversion to ",
        selectedTimeZone.value,
        " is: ",
        fromCombinedTime
      );
      epochStart = TimeManipulators.convertToEpoch(
        fromCombinedTime,
        selectedTimeZone.value
      );
      console.log("Epoch start time is: ", epochStart);
      toCombinedTime = TimeManipulators.combineDateTime(
        selectedDate,
        toTime.hour().toString(),
        toTime.minute().toString()
      );
      console.log("To Time in local time zone is: ", toCombinedTime);
      toCombinedTime = TimeManipulators.changeTimezone(
        toCombinedTime,
        selectedTimeZone.value
      );
      console.log(
        "Resultant time from time zone conversion to ",
        selectedTimeZone.value,
        " is: ",
        toCombinedTime
      );
      epochEnd = TimeManipulators.convertToEpoch(
        toCombinedTime,
        selectedTimeZone.value
      );
      console.log("Epoch end time is: ", epochEnd);
      const formattedDate =
        TimeManipulators.extractDateStringFromDate(selectedDate);
      changeInviteValue(
        formattedDate +
          " " +
          formattedFromTime +
          " - " +
          formattedToTime +
          " " +
          selectedTimeZone.label
      );
    } else {
      fromCombinedTime = TimeManipulators.combineDateTime(
        selectedDate[0],
        fromTime.hour().toString(),
        fromTime.minute().toString()
      );
      fromCombinedTime = TimeManipulators.changeTimezone(
        fromCombinedTime,
        selectedTimeZone.value
      );
      epochStart = TimeManipulators.convertToEpoch(
        fromCombinedTime,
        selectedTimeZone.value
      );
      toCombinedTime = TimeManipulators.combineDateTime(
        selectedDate[1],
        toTime.hour().toString(),
        toTime.minute().toString()
      );
      toCombinedTime = TimeManipulators.changeTimezone(
        toCombinedTime,
        selectedTimeZone.value
      );
      epochEnd = TimeManipulators.convertToEpoch(
        toCombinedTime,
        selectedTimeZone.value
      );
      console.log("Epoch start time is: ", epochStart);
      console.log("Epoch end time is: ", epochEnd);
      const fromFormattedDate = TimeManipulators.extractDateStringFromDate(
        selectedDate[0]
      );
      const toFormattedDate = TimeManipulators.extractDateStringFromDate(
        selectedDate[1]
      );
      changeInviteValue(
        fromFormattedDate +
          " " +
          formattedFromTime +
          " - " +
          toFormattedDate +
          " " +
          formattedToTime +
          " " +
          selectedTimeZone.label
      );
    }
    // console.log('Selected Time Zone is: ',selectedTimeZone)
    setInvitationValue({
      epoch_start: parseInt(epochStart),
      epoch_end: parseInt(epochEnd),
      temporary_users: [],
    });
    changeShowInvite(event);
  };

  if (showInviteMenu === true)
    return (
      <div>
        {snackbar && (
          <CustomSnackbar
            messageType={snackbarMessageType}
            message={snackbarMessage}
          />
        )}
        <div className="modal-wrapper" onClick={closeModalDisplay} />
        <InvitePicker
          changeShowInvite={changeShowInvite}
          changeInviteValue={changeInviteValue}
          showSnackbar={showSnackbar}
          setInvite={setInvite}
          selectedTimeZone={selectedTimeZone}
          changeTimeZone={(timezone) => setSelectedTimeZone(timezone)}
        />
      </div>
    );
  else
    return (
      <div>
        {snackbar && (
          <CustomSnackbar
            messageType={snackbarMessageType}
            message={snackbarMessage}
          />
        )}
        <div className="modal-wrapper" onClick={closeModalDisplay}></div>
        <div className="create-conference-modal">
          <div
            className={
              config.name === "Generic"
                ? "create-conference-heading-box"
                : "create-conference-heading-box chiems"
            }
          >
            <h5>Create a Conference</h5>
          </div>
          <form className="create-conference-form" onSubmit={createhandler}>
            <input
              type="file"
              accept=".pdf,.docx"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <h4>Name</h4>
            <div className="conference-form-row">
              <input
                className="conference-form-input"
                value={firstName}
                onChange={changeFirstNameValue}
              ></input>
              <div className="conference-divider">
                <p> - </p>
              </div>
              <input
                className="conference-form-input"
                value={lastName}
                onChange={changeLastNameValue}
                placeholder="Enter unqiue id"
              ></input>
            </div>
            <h4>Invite Participants</h4>
            <textarea
              className="conference-form-input box"
              value={participantList}
              onChange={changeParticipants}
            ></textarea>
            {/*Uncomment the following lines to enable invite feature in create conference" */}
            <div className="invite-row">
              <i className="fa-regular fa-calendar"></i>
              {inviteValue.length > 0 && <p>&nbsp;{inviteValue}</p>}
              {inviteValue.length === 0 && <p>&nbsp;Pick date & time - </p>}
              {inviteValue.length === 0 && (
                <span onClick={changeShowInvite}>&nbsp;Click here</span>
              )}
              {inviteValue.length > 0 && (
                <span onClick={changeShowInvite}>&nbsp;Edit here</span>
              )}
            </div>
            <h4>Choose a Presentation PDF</h4>
            {pdfValue == null && (
              <div className="invite-row">
                <p>To upload a PDF File(max 300 Kb),</p>
                <span onClick={handleButtonClick}>&nbsp;Click here</span>
              </div>
            )}
            {pdfValue && (
              <div className="conference-form-row">
                <p className="pdf-name-style">{pdfValue.name}</p>
                <i className="fa-solid fa-xmark" onClick={removePDFValue}></i>
              </div>
            )}
            <h4>Preferences</h4>
            <div className="conference-form-row">
              <p className="conference-preference">
                Allow users to listen only
              </p>
              <ToggleSwitch
                switchValue={listenOnly}
                toggleValue={toggleListenOnly}
              />
            </div>
            <div className="conference-form-row end">
              <p className="conference-preference">
                Only allow users with Webcam on
              </p>
              <ToggleSwitch
                switchValue={webCamOn}
                toggleValue={toggleWebcamOn}
              />
            </div>
            <CustomButton
              customClassName={
                config.name === "Generic"
                  ? "primary-button conference"
                  : "primary-button conference chiems"
              }
              buttonType={"submit"}
              isdisabled={isloading}
            >
              {!isloading && "Create"}
              {isloading && (
                <Loadingspinner customClassName="loading-spinner secondary" />
              )}
            </CustomButton>
          </form>
        </div>
      </div>
    );
}

export default CreateConferenceModal;
